<template>
  <div class="learning">
    <!-- pc端 -->
    <div class="header_center" v-if="!_isMobile()">
      <div class="center1"></div>
      <div class="contain">
        <div class="center2">
          <ul>
            <li v-for="(item, index) in cardList" :key="index">
              <img :src="item.photo" />
              <div class="about2">
                <span class="about_name">{{ item.name }}</span>
                <span class="about_title">{{ item.title }}</span>
              </div>
              <div class="honor">
                <p class="about3" v-for="hor in item.honor" :key="hor">
                  {{ hor }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <p class="xing">（按姓名音序，排名不分先后）</p>
    </div>
    <MyFooter class="footer" v-if="!_isMobile()" />
    <!-- 移动端 -->
    <div class="moblie-box" v-if="_isMobile()">
      <img src="@/assets/moblie/bg_03.png" class="text" />
      <div class="contain">
        <div class="center2">
          <div class="card-box" v-for="(item, index) in cardList" :key="index">
            <img :src="item.photo" />
            <div class="about">
              <span class="about_name">{{ item.name }}</span>
              <span class="about_title">{{ item.title }}</span>
            </div>
            <div class="honor">
              <p class="about3" v-for="hor in item.honor" :key="hor">
                {{ hor }}
              </p>
            </div>
          </div>
          <p class="xing">（按姓名音序，排名不分先后）</p>
        </div>
      </div>
      <img src="@/assets/moblie/bg_05.png" class="text2" />
    </div>
    <MyMoblieFooter class="footer" v-if="_isMobile()" />
  </div>
</template>

<script>
export default {
  name: 'Learning',
  data () {
    return {
      cardList: [
        {
          photo: require('@/assets/enter/enter_1.png'),
          name: '陈志武',
          title: '香港大学冯氏讲席教授',
          honor: [
            '耶鲁大学金融学博士',
            '香港大学亚洲环球研究所所长',
            '香港大学经济与工商管理学院冯氏讲席教授',
            '清华大学社会科学学院特聘教授',
            '中国证监会国际顾问'
          ]
        },
        {
          photo: require('@/assets/enter/enter_2.png'),
          name: '邓兴旺',
          title: '北京大学现代农学院教授',
          honor: [
            '加利福尼亚大学伯克利分校博士',
            '美国科学院院士',
            '北京大学现代农业研究院院长',
            '原耶鲁大学讲席教授'
          ]
        },
        {
          photo: require('@/assets/enter/enter_6.png'),
          name: '马德斌',
          title: '牛津大学经济史教授',
          honor: [
            '曾任日本一桥大学经济研究所教授',
            '国际经济史协会秘书长和理事',
            '亚洲经济史学会和国内量化历史研究的发起人之一'
          ]
        },
        {
          photo: require('@/assets/enter/enter_7.png'),
          name: '钱思进',
          title: '北京大学物理学院教授',
          honor: [
            '北京大学物理学院教授、博士生导师',
            '欧洲核子研究中心（CERN）资深客座研究员'
          ]
        },
        {
          photo: require('@/assets/enter/enter_9.png'),
          name: '孙隆基',
          title: '原孟菲斯大学历史系教授',
          honor: ['斯坦福大学历史学博士', '原孟菲斯大学历史系教授']
        },
        {
          photo: require('@/assets/enter/enter_10.png'),
          name: '王 笛',
          title: '澳门大学历史系教授',
          honor: [
            '澳门大学历史系教授、系主任',
            '曾任美国得克萨斯A&M大学历史系教授',
            '旅美中国历史学会（CHUS）前会长'
          ]
        },
        {
          photo: require('@/assets/enter/enter_3.png'),
          name: '王缉思',
          title: '北京大学国际关系学院教授',
          honor: [
            '北京大学国际战略研究院院长、教授',
            '中华美国学会荣誉会长',
            '曾任中国社会科学院美国研究所研究员、所长',
            '北京大学国际关系学院院长'
          ]
        },
        {
          photo: require('@/assets/enter/enter_4.png'),
          name: '谢丹阳',
          title: '香港科技大学工商管理学院教授',
          honor: [
            '芝加哥大学博士',
            '香港科技大学工商管理学院经济系教授',
            '曾任国际货币基金组织（IMF）高级经济学家',
            '香港外汇基金咨询委员会货币发行委员会委员',
            '交银施罗德基金管理有限公司独立董事'
          ]
        },
        {
          photo: require('@/assets/enter/enter_8.png'),
          name: '熊秉元',
          title: '浙江大学法律与经济研究中心教授',
          honor: [
            '浙江大学经济学院讲座教授',
            '浙江大学法律与经济研究中心主任',
            '曾在香港城市大学、澳门大学、北京大学等高校担任客座教授'
          ]
        },
        {
          photo: require('@/assets/enter/enter_5.png'),
          name: '张和平',
          title: '耶鲁大学生物统计学讲席教授',
          honor: [
            '耶鲁大学医学院统计与科学合作中心主任',
            '耶鲁大学Susan Dwight Bliss生物统计学讲席教授',
            '香港大学荣誉教授'
          ]
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.moblie-box {
  display: flex;
  flex-flow: column;
  margin-top: -1px;
  .text {
    display: block;
    width: 100%;
  }
  .text2 {
    display: block;
    width: 100%;
  }
  .center2 {
    width: 100%;
    margin-bottom: 10px;
    padding: 0.14rem;
    display: flex;
    flex-flow: row wrap;
    .card-box {
      display: flex;
      flex-flow: column;
      width: 3.42rem;
      background: #ffffff;
      box-shadow: 0px 0px 28px 0px rgba(230, 230, 230, 0.5);
      border-radius: 10px;
      margin: 2%;
      flex-shrink: 0;
      flex: 0 0 46%;
      img {
        width: 100%;
        background: #5b666e;
        border-radius: 10px 10px 0px 0px;
      }
      .about {
        display: flex;
        flex-flow: column;
        padding: 0.18rem 0.18rem 0 0.18rem;
        .about_name {
          font-size: 0.28rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .about_title {
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #333333;
        }
      }
      .honor {
        padding: 0.08rem 0.18rem 0.18rem 0.18rem;
        .about3 {
          font-size: 0.16rem;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #b5b5b5;
        }
      }
    }
  }
  .xing {
    width: 100%;
    font-size: 0.24rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    text-align: center;
    margin-top: 0.2rem;
  }
}
.header_center {
  height: 100%;
  margin-bottom: 119px;
  .center1 {
    width: 100%;
    height: 448px;
    background: url("~@/assets/enter/enter_bg.png") no-repeat center / cover;
    margin-bottom: 30px;
  }
  .center2 {
    width: 1200px;
    margin-bottom: 21px;
    ul {
      width: 100%;
      li {
        display: block;
        float: left;
        width: 24%;
        height: 305px;
        background: #ffffff;
        box-shadow: 0px 0px 28px 0px rgba(230, 230, 230, 0.5);
        border-radius: 10px;
        list-style: none;
        margin: 0 1% 2% 0;
        &:nth-child(4n) {
          margin-right: 0;
        }
        img {
          width: 100%;
          height: 156px;
          background: #5b666e;
          border-radius: 10px 10px 0px 0px;
        }
        .about2 {
          margin-left: 10px;
          margin-top: 10px;
          .about_name {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          .about_title {
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #333333;
            line-height: 17px;
            margin-left: 16px;
          }
        }
        .honor {
          height: 90px;
          margin: 8px 0 12px 10px;
          .about3 {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #b5b5b5;
            line-height: 18px;
          }
        }
      }
    }
  }
  .xing {
    height: 27px;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 27px;
    text-align: center;
  }
}
</style>
